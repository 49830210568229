<script>
import {Bar, mixins} from "vue-chartjs";

export default {
  name: "BarChart",
  extends: Bar,
  mixins: [mixins.reactiveProp],
  props: ["chartData", "chartOptions"],
  mounted() {
    this.renderChart(this.chartData, this.chartOptions);
  },
};
</script>
<style lang="scss" scoped>
#bar-chart {
  width: 100% !important;
  max-height: 550px !important;
}
</style>
